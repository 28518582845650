<template>
  <zmAppPage class="selectBook" :showLeft="true" :showBack="false" :showHeaderLeft="true" :show-header="true">
    <div slot="header">
      <filter-bar class="mt5" @change="onSearch"></filter-bar>
    </div>
    <div class="book-container cf">
      <zm-list :get-list="getList" ref="refList" :extra-params="query" @after-fetch="afterFetch">
        <template slot-scope="{ listData }">
          <div
            v-for="(item, index) in listData"
            :key="index"
            class="item df-center"
            :class="{ active: item.id === currentBook.id }"
            @click="onClick(item)"
          >
            <div class="inner" :class="{ 'has-buy': item.haveBuy === 1 }">
              <div
                class="cover"
                :style="{
                  backgroundImage: `url(${item.cover ||
                    defaultCover}?x-oss-process=image/resize,w_200,m_lfit,color_b4ddff)`
                }"
              ></div>
              <p class="ellipsis tc title">{{ item.fullName || '课本' }}</p>
            </div>
          </div>
        </template>
      </zm-list>
    </div>
  </zmAppPage>
</template>

<script>
import config from '@/config/index';
import defaultCover from '@/assets/img/default/cover.jpg';
import FilterBar from './FilterBar.vue';
import bookApi from '@/api/src/book';
import { mapActions, mapGetters } from 'vuex';
import * as auth from '@/utils/auth';
const appInfo = auth.loc.get('appInfo', {});
export default {
  name: 'Home',
  components: { FilterBar },
  data() {
    return {
      list: [],
      defaultCover,
      query: {
        grade: '',
        haveBuy: -1,
        press: '',
        usingFlag: '',
        ids: appInfo.bookId
      }
    };
  },
  computed: {
    ...mapGetters(['currentBook'])
  },
  watch: {
    // 'currentBook.id'(){
    // },
  },
  created() {
    this.getList();
  },
  methods: {
    ...mapActions('app', ['setCurrentBook', 'setUnitIndex', 'setHomeTab', 'setUnitIndex']),
    // async getList() {
    //   const appInfo = auth.loc.get('appInfo', {});
    //   const res = await book.getList({ pageNo: 0, pageSize: 99, ids: appInfo.bookId });
    //   this.list = [...res.data];
    //   if (!this.currentBook.id && this.list.length) {
    //     this.setCurrentBook(this.list[0]);
    //   }
    // },
    afterFetch(res) {
      if (!this.currentBook.id && res.data.length) {
        this.setCurrentBook(res.data[0]);
      }
    },
    getList(params) {
      return bookApi.getList(params);
    },
    onSearch(query) {
      const appInfo = auth.loc.get('appInfo', {});
      this.query = { ...query, ids: appInfo.bookId };
      this.$refs.refList.onSearch();
    },
    onClick(item) {
      if (item.id !== this.currentBook.id) this.setUnitIndex(0);
      this.setCurrentBook(item);
      this.setHomeTab('Unit');
    }
  }
};
</script>

<style lang="scss">
.zm-appPage.is-hasHeader.selectBook {
  padding-top: 1.8rem;
}
</style>
<style lang="scss" scoped>
@import '~@/styles/varHelper/_var.scss';
.book-container {
  padding: 0.5rem 0.5rem;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.active {
      .inner {
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 0.08rem;
          box-shadow: inset 0 0 0.08rem 2px lighten($--color-primary, 5%);
        }
      }
    }
    .inner {
      background: darken($--color-primary, 0%);
      display: inline-block;
      width: 2.78rem;
      position: relative;
      overflow: hidden;
      border-radius: 0.1rem;
      &.has-buy {
        &::after {
          content: '已购买';
          color: #fff;
          display: inline-block;
          line-height: 0.4rem;
          text-align: center;
          transform: translate(35%, 50%) rotate(40deg);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 0.4rem;
          background: red;
          font-size: 12px;
        }
      }
    }
    width: 50%;
    overflow: hidden;
    border-radius: 0.08rem;
    margin-bottom: 0.34rem;
    float: left;
    .title {
      // margin-top: 0.1rem;
      background: darken($--color-primary, 0%);
      padding: 0.1rem 0.2rem;
      color: #fff;
    }
  }
}
.cover {
  width: 2.78rem;
  height: 2.78rem;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: cover;
}
</style>
