<!-- MainTabbar  -->
<template>
  <van-tabbar v-model="active" :active-color="$varColor.mainColor" safe-area-inset-bottom>
    <van-tabbar-item v-for="(item, index) in tabbarList" :key="index" :name="item.value" @click="tabClick(item)">
      <span>{{ item.name }}</span>
      <ZmIcon slot-scope="props" slot="icon" :icon-class="props.active ? item.icon : item.activeIcon"></ZmIcon>
      <!-- <img class="img_icon" slot="icon"  :src="props.active ? item.checkImg : item.unCheckImg" /> -->
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import {tabbarList} from '@/model/dict';
export default {
  name: 'MainTabbar',
  data() {
    return {
      //   active: 'Home',
      tabbarList
    };
  },

  computed: {
    ...mapGetters(['mainTab']),
    active: {
        get() {
            return this.mainTab;
        },
        set(v) {
            this.setHomeTab(v);
        }
    }
  },
  created() {
    const item = this.tabbarList.find(item => item.value === this.active);
    this.tabClick(item);
  },
  methods: {
    ...mapActions('app',['setCurrentBook','setHomeTab']),
    tabClick(item) {
      this.$emit('change', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.img_icon {
  width: 0.4rem;
  height: 0.4rem;
}
</style>
