<template>
  <zmAppPage
    :show-footer="showFooter"
    :showHeaderLeft="false"
    :showHeaderRight="mainTab === 'Unit'"
    :show-header="true"
    :title="title"
  >
    <a href="javascript:;" class="color-white" v-if="mainTab === 'Unit'" slot="headerRight" @click="setHomeTab('Home')"
      >切换教材</a
    >
    <component :is="mainTab"></component>
    <MainTab slot="footer" @change="tabChange"></MainTab>
  </zmAppPage>
</template>

<script>
import MainTab from '@/pages/index/components/MainTab';
import Home from '@/pages/index/components/Home';
import Unit from '@/pages/index/components/Unit';
import Mine from '@/pages/index/components/Mine/index';
import { mapGetters,mapActions } from 'vuex';
import { tabbarList } from '@/model/dict';
import * as auth from '@/utils/auth';
export default {
  name: 'Index',
  components: { MainTab, Home, Unit, Mine },
  data() {
    return {
      componentName: 'Home'
    };
  },
  computed: {
    showFooter() {
      return true;
    },
    title() {
      const res = tabbarList.find(item => {
        return item.value === this.mainTab;
      });
      return this.mainTab === 'Unit' ? this.currentBook.fullName : res.name;
    },
    ...mapGetters(['mainTab','currentBook'])
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions('app',['setHomeTab']),
    tabChange(item) {
      this.componentName = item.value;
    }
  }
};
</script>
