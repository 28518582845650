<!--/**
* Author: ganqz
* Date: 2021-05-28 00:16
* Desc: FilterBar
*/
-->
<template>
  <div class="FilterBar">
    <van-tabs type="card" v-model="form.haveBuy" :color="mainColor">
      <van-tab title="我的教材" :name="1"></van-tab>
      <van-tab title="全部教材" :name="-1"></van-tab>
    </van-tabs>
    <van-dropdown-menu>
        <van-dropdown-item v-model="form.press" :options="pressList" />
        <van-dropdown-item v-model="form.grade" :options="gradeList" />
        <van-dropdown-item v-model="form.usingFlag" :options="usingFlagList" />
    </van-dropdown-menu>
  </div>
</template>

<script>
import bookApi from '@/api/src/book';
import {usingFlagList,gradeList} from '@/model/dict';
import style from '@/styles/varHelper/_var.scss';
export default {
  name: 'FilterBar',
  components: {},
  data() {
    return {
      menuActive: '',
      check: {},
      usingFlagList: usingFlagList(),
      gradeList: gradeList(),
      pressList: [],
      show: false,
      mainColor: style.mainColor,
      form: {
        grade: '',
        haveBuy: -1,
        press: '',
        usingFlag: ''
      }
    };
  },
  computed: {
      query() {
        return this.$route.query;
      }

  },
  watch: {
      form: {
          handler(val) {
              this.$emit('change',val);
          },
          deep: true
      }
  },
  async created() {
    await this.getPress();
  },
  methods: {
    async getPress() {
      const res = await bookApi.getPress();
      this.pressList  = res.map((item) => {
          return {
              text: item,
              value: item
          };
      });
      this.pressList.unshift({
          text: '全部出版社',
          value: ''
      });
    }
  }
};
</script>
<style lang="scss">
    .van-tabs__nav--card{
        margin:0;
    }
</style>
<style lang="scss" scoped>
.triangle {
//   transform: rotate(180deg);
  transition: all 0.1s;
}

.popup-content {
  font-size: 0.26rem;
}

.search-content {
  max-height: 7rem;
}

.item-cell-box {
  width: 100%;
  padding: 0 0.3rem;
  box-sizing: border-box;
}

.choose-item {
  margin-top: 0.3rem;
  &:last-child {
    padding-bottom: 0.3rem;
  }
}

.search-footer {
  padding: 0.3rem;
  box-sizing: border-box;
}

.sureBtn {
  width: 3.3rem;
  height: 0.88rem;
  line-height: 0.88rem;
}
.FilterBar,
.popup-content {
  .item-box {
    width: 100%;
    height: 0.89rem;
    background: #fff;
    border-bottom: 1px solid #dedede;
    color: #999;
    text-align: center;
    font-size: 0.26rem;
    .active {
      color: $--color-primary;
      .triangle {
        transform: rotate(0deg) !important;
      }
    }
  }
  .zm-tabs {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    width: 100%;
    box-sizing: border-box;
    .title {
      // width: 2.2rem;
    }
  }
}
</style>
