<template>
  <div class="pd20 bg-white">
    <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
      <van-cell v-for="(item, index) in unitList" :key="index" is-link @click="clickItem(item, index)">
        <div class="icon-box" slot="icon">
          <van-icon class="mine-icon text-primary" name="share" v-if="unitIndex === index" />
        </div>
        <div slot="title">
          {{ `${item.fullName}` }}
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import * as path from '@/router/routes/routePath';
import bookApi from '@/api/src/book';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Unit',
  components: {},
  data() {
    return {
      loading: false,
      finished: false,
      list: []
    };
  },
  computed: {
    ...mapGetters(['currentBook', 'unitIndex', 'unitList'])
  },
  watch: {
    // 'currentBook.id'(){
    //     this.setUnitIndex()
    // },
  },
  created() {},
  methods: {
    ...mapActions('app', ['setUnitIndex', 'setUnitList', 'setPageContent']),
    async onLoad() {
      if (!this.currentBook.id) this.$msg.showMsg('请选择课本');
      const res = await bookApi.getUnitList({
        pageNo: 0,
        pageSize: 99,
        bookId: this.currentBook.id
      });
      this.setUnitList(res.data);
      this.loading = false;
      if (res.data.length < res.pageSize) this.finished = true;
    },
    async clickItem(item, index) {
      await this.setPageContent(index);
      this.setUnitIndex(index);
      this.$router.push({ path: path.PATH_UNIT_PLAY, query: { unitId: item.id } });
      this.$emit('change', false);
    }
  }
};
</script>
<style lang="scss" scoped>
.icon-box {
  width: 0.44rem;
  height: 0.44rem;
  vertical-align: middle;
}
</style>
